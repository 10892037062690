<template>
    <div>
        <b-modal ref="modal_setor_ww" hide-footer title="Leia o QRCode">
            <div class="d-block text-center">
                <span id="caixa_qrcode_modal_setor">O nome da sessão deve ser o mesmo informado na criação do setor</span>
            </div>
        </b-modal>

        <b-modal v-model="showModal" hide-header>

            <h3>Atenção!</h3>
            <p>
                Existem
                <b>
                    {{quantidadeAtendimentosSetor}} Atendimento{{quantidadeAtendimentosSetor > 1 ? 's' : ''}}
                </b>
                ativos ou pendentes no setor <b>{{nomeSetor}}</b>.
            </p>
            <p>O que deseja fazer?</p>
            <div class="container">
                <div class="row p-2 my-2" style="background-color: var(--cinza-3);  flex-wrap: nowrap !important;">
                    <input v-model="selecionado" type="radio" value="1" id="radio1" name="exclusaoSetor" />
                    <label class="ml-2 mb-1 w-100" for="radio1">Finalizar os Atendimentos</label>
                </div>
                <div class="row p-2 my-2 accordion-content" style="background-color: var(--cinza-3);">
                    <input v-model="selecionado" type="radio" value="2" id="radio2" name="exclusaoSetor"/>
                    <label class="ml-2 mb-1 largura-label" for="radio2">Transferir para outro setor</label>

                    <div v-if="selecionado == 2">
                        <select v-model="setorIdSelecionado" class="w-100 py-1 border-0 animationSelectSetor">
                            <option :value="null" selected>Selecione um setor</option>
                            <option v-for="data in lista.filter(value => value.Id != setorId)" :value="data.Id">{{data.Nome}}</option>
                        </select>
                    </div>
                </div>
                <div class="row p-2 my-2 d-block" style="background-color: var(--cinza-3); flex-wrap: nowrap !important; ">
                    <input v-model="selecionado" type="radio" value="3" id="radio3" name="exclusaoSetor" />
                    <label class="ml-2 mb-1 largura-label" for="radio3">Transferir para um usuário</label>

                    <div v-if="selecionado == 3">
                        <select v-model="usuarioSetorId" class="w-100 py-1 border-0">
                            <option :value="null" selected>Selecione um Usuario/Setor</option>
                            <option v-for="data in listaUsuarioSetoresSemRepeticao" 
                                    :value="{ 'usuarioId': data.UsuarioId, 'setorId': data.SetorId }">
                                {{data.Usuario.Nome}} {{data.Usuario.Sobrenome}}/{{data.Setor.Nome}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <template #modal-footer="{ ok, cancel }">
                <b-button variant="white"
                          class="rounded-0 border-0"
                          @click="cancel()">
                    Cancelar
                </b-button>
                <b-button variant="success"
                          :disabled="(selecionado == 1 || (selecionado == 2 && setorIdSelecionado != null) || (selecionado == 3 && usuarioSetorId != null)) ? false : true"
                          class="rounded-0 border-0"
                          @click="confirmarExclusao(ok)">
                    Confirmar
                </b-button>
            </template>
        </b-modal>

        <b-container fluid class="px-0">
           
            <b-table class="lista-dados mb-0"
                     hover
                     sort-icon-left
                     :items="lista"
                     :fields="colunas"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     responsive="sm">

                <template #cell(Inclusao)="{ item }">
                    {{ item.InclusaoFormatada }}
                </template>

                <template #cell(Alteracao)="{ item }">

                    {{ item.AlteracaoFormatada }}
                </template>

                <template #cell(TelefoneWhatsWeb)="{ item }">
                    {{ item.HabilitarWhatsWeb == "Sim" ? item.TelefoneWhatsWeb : '' }}
                </template>

                <template #cell(Status)="{ item }">
                    <p :style="item.Status == 200 ? 'color: green' : 'color: #d00000;'">
                        <b-spinner v-if="item.Status == null && item.HabilitarWhatsWeb == 'Sim'" variant="primary" label="Spinning" small></b-spinner>
                        <span v-else>{{ item.HabilitarWhatsWeb == 'Sim' ? (item.Status == 200 ? "Conectado" : "Desconectado") : "" }}</span>
                    </p>
                </template>

                <template #cell(botoes)="row">
                    <div class="text-center">
                        <div class="btn_conectar_WhatsW">

                            <button type="button" class="btn btn-sm btn-success" :style="row.item.HabilitarWhatsWeb != 'Sim' || row.item.Status == 200 ? 'visibility: hidden;' : ''" v-on:click="abrir_modal_whats(row.item)">WhatsWeb</button>
                        </div>
                        <botao-tab-edit @click="$emit('editar', row.item)" />
                        <botao-tab-remove @click="abriModalExclusao(row.item)" />
                    </div>
                </template>

            </b-table>

        </b-container>

    </div>
</template>

<script>
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'

    export default {
        name: 'TabelaSetores',
        components: {
            BotaoTabEdit,
            BotaoTabRemove,

        },
        props: {
            colunas: {
                type: Array,
                default: () => []
            },
            lista: {
                type: Array,
                default: () => []
            },
            listaUsuarioSetores: {
                type: Array,
                default: () => []
            },
            listaIdAtendimentos: {
                type: Array,
                default: () => []
            }, 
            dadosSetor:null
        },
        data() {
            return {
                sortBy: this.colunas[0].key,
                sortDesc: false,
                telefone: '',
                showModal: false,
                quantidadeAtendimentosSetor: 0,
                setorId: '',
                setorIdSelecionado: null,
                selecionado: null,
                usuarioSetorId: null,
                listaUsuarioSetoresSemRepeticao: null,
                nomeSetor: ''
            }
        },
        methods: {
            abrir_modal_whats: function (dados) {
                this.$store.dispatch('setLoadingGlobal', true)
                this.$refs['modal_setor_ww'].show()
                if (dados.CaminhoWhatsWeb) {
                    const WhatsWebConnectWindowConfig = `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
                    const hostOmni = this.$hostOmni
                    setTimeout(function () {
                        window.open(
                            dados.CaminhoWhatsWeb + `:3333/start?dominio=${hostOmni}&session=${dados.sessionWhatsWeb}`,
                            'WhatsAppConnect',
                            WhatsWebConnectWindowConfig
                        ).focus();
                    }, 1500);

                }
                this.$store.dispatch('setLoadingGlobal', false)
                this.telefone = dados.TelefoneWhatsWeb
            },
            formatarDataHora(jsonDate) {
                return new Date(jsonDate).toLocaleString('pt-br')
            },
            abriModalExclusao(item) {
                try {
                    this.quantidadeAtendimentosSetor = this.listaIdAtendimentos.filter(value => value.SetorId == item.Id).length
                    if (this.quantidadeAtendimentosSetor == 0) {
                        var data = {
                            'Id': item.Id,
                        }
                        this.$emit('remover', data, 'Esse Setor não possui atendimento. Deseja Excluir?')
                        return
                    }
                    this.selecionado = null
                    this.setorIdSelecionado = null
                    this.usuarioSetorId = null
                    this.nomeSetor = item.Nome

                    this.setorId = item.Id
                    this.listaUsuarioSetoresSemRepeticao = this.listaUsuarioSetores.filter((value, index, self) => value.SetorId != this.setorId
                        && (self.map(m => m.UsuarioId).indexOf(value.UsuarioId) === index || self.map(m => m.SetorId).indexOf(value.SetorId) === index))

                    this.listaUsuarioSetoresSemRepeticao.filter(value => value.SetorId != item.Id)
                    this.showModal = true
                } catch (e) {
                    console.log(e)
                }
            },
           
            confirmarExclusao(ok) {
                var msg = `Deseja mesmo Finalizar ${this.quantidadeAtendimentosSetor} Atendimento${this.quantidadeAtendimentosSetor > 1 ? 's' : ''}`
                var data = {
                    'Id': this.setorId,
                }

                if (this.selecionado == 2 && this.setorIdSelecionado) {
                    data['TransferenciaSetorId'] = this.setorIdSelecionado
                    var setorSelecionado = this.lista.find(value => value.Id == this.setorIdSelecionado)
                    msg = `Transferir ${this.quantidadeAtendimentosSetor} Atendimento${this.quantidadeAtendimentosSetor > 1 ? 's' : ''} para o Setor ${setorSelecionado.Nome}`
                }
                else if (this.selecionado == 3 && this.usuarioSetorId) {
                    data['UsuarioAndSetorIdsDTO'] = {
                        'UsuarioId': this.usuarioSetorId.usuarioId,
                        'SetorId': this.usuarioSetorId.setorId
                    }
                    var usuarioSetor = this.listaUsuarioSetoresSemRepeticao.find(f => f.UsuarioId == this.usuarioSetorId.usuarioId && f.SetorId == this.usuarioSetorId.setorId)
                    msg = `Transferir ${this.quantidadeAtendimentosSetor} Atendimento${this.quantidadeAtendimentosSetor > 1 ? 's' : ''} para o ${usuarioSetor.Usuario.Nome}/${usuarioSetor.Setor.Nome}`
                }

                this.$emit('remover', data, msg)

                ok()
            }
        }
    }
</script>

<style scoped>
    .lista-dados {
        font-size: 0.8rem;
    }

    .card-setores {
        height: 100%;
    }

    .contador-itens {
        width: 100%;
        font-size: 16px;
        font-weight: 700;
    }

    .btn_conectar_WhatsW {
        display: inline-block;
        width: 50px;
        margin-right: 55px;
    }

    .largura-label {
        width: 95%;
    }

    .accordion-content {
        transition: max-height 0.15s ease-out;
        overflow: hidden;
        display: block;
    }
        .accordion-content:active {
            transition: max-height 0.25s ease-in;
        }
</style>